<template>
  <div class="d-flex flex-column">
    <v-row align="center">
      <v-col cols="12" sm="4" md="4">
        <v-btn
          color="takein"
          class="white--text my-2"
          :ripple="false"
          depressed
          @click="addBusinessHour"
        >
          {{ $t('Common.add_businesshours') }}
          <v-icon right small>fas fa-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      justify="space-between"
      align="center"
      v-for="(business_hour, key) in business_hours"
      no-gutters
      :key="key"
    >
      <v-col cols="12" sm="5" lg="6">
        <ValidationProvider v-slot="{ errors }" name="Days" rules="required">
          <v-select
            prepend-icon="calendar_today"
            class="body-2"
            multiple
            :items="[
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
              'Sunday'
            ]"
            v-model="business_hours[key].business_days"
            label="Open days"
            :error-messages="errors"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="5" sm="3" lg="2">
        <ValidationProvider v-slot="{ errors }" name="from" rules="required">
          <v-select
            :items="startHourList"
            v-model="business_hours[key].fromHour"
            label="from"
            :error-messages="errors"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="5" sm="3" lg="2">
        <ValidationProvider v-slot="{ errors }" name="to" rules="required">
          <v-select
            :items="endHourList"
            v-model="business_hours[key].endHour"
            label="to"
            :error-messages="errors"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="2" sm="1" lg="2">
        <v-btn text small color="error" @click="removeHour(key)">
          <v-icon dark>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    isBusinessClose: {
      Type: Boolean
    },
    businessHours: {
      Type: Array
    },
    isNotApproved: {
      Type: Boolean
    }
  },
  data() {
    return {
      is_business_close: false,
      business_hours: [],
      startHourList: null,
      endHourList: null
    }
  },
  mounted() {
    this.is_business_close = !!this.isBusinessClose
    this.business_hours = this.businessHours || []
    this.startHourList = this.eachHalfHour('00:00', `23:30`)
    this.endHourList = this.eachHalfHour(`00:00`, '23:30')
  },
  watch: {
    businessHours() {
      this.business_hours = this.businessHours || []
    },
    business_hours: {
      handler: function() {
        // TODO: lets remove adding weekday after release 1.3.3 of mobile app.
        for (let index = 0; index < this.business_hours.length; index++) {
          let element = this.business_hours[index]
          element.weekday = this.business_hours[index].business_days
          this.business_hours[index] = element
        }
        this.$emit('onBusinessHoursUpdate', this.business_hours)
      },
      deep: true
    }
  },
  methods: {
    addBusinessHour() {
      this.business_hours.push({ fromHour: '08:00 am', endHour: '08:00 pm' })
    },
    removeHour(key) {
      this.business_hours.splice(key, 1)
    },
    updateIsBusinessClose() {
      this.$emit('onBusinessClose', { isBusinessClose: this.is_business_close })
    }
  }
}
</script>
